<template>
  <FocusTrap>
    <div class="card">
      <div class="card-header header-elements-sm-inline py-sm-0" >
        <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Vehicle's List</h5>
        <div class="header-elements">
          <form action="#">
            <div class="form-group-feedback form-group-feedback-right">
              <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
              <div class="form-control-feedback">
                <i class="icon-search4 font-size-base text-muted"></i>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="card-body" style="padding: 0px;">
        <div class="table-responsive">
          <table id="vehicle_table"
                 class="table table-bordered table-condensed table-columned"
                 data-search="false"
                 data-show-refresh="false"
                 data-show-columns="false"
                 data-pagination="false"
                 data-page-list="[10, 25, 50, 100, ALL]"
                 data-show-footer="false"
                 data-toggle="context"
                 data-target=".context-table">
            <thead>
            <tr>
              <th data-field="id" data-class="d-none">ID</th>
              <th data-formatter="runningFormatter" data-width="75">S.No</th>
              <th data-field="status" data-width="100" data-sortable="true">Stats</th>
              <th data-field="name" data-sortable="true">Name</th>
              <th data-field="category.name" data-width="200" data-sortable="true">Category</th>
              <th data-field="owner.name" data-width="200" data-sortable="true">Owner</th>
            </tr>
            </thead>
            <tbody>
            </tbody>
            <tfoot>
            </tfoot>
          </table>
        </div>
      </div>
      <!-- Context Menu -->
      <div id="context-menu" class="context-table">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" style="color: red"> <i class="icon-blocked" ></i>Delete</a>
        </div>
      </div>
      <!-- End of Context Menu -->


      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
            <i class="fab-icon-open icon-plus3"></i>
            <i class="fab-icon-close icon-plus3"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->

      <vmodal name="vehicle_window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="650" :height="375" @before-open="beforeOpen" @before-close="beforeClose"  >
        <VehicleForm v-bind:myvehicle="vehicle" v-on:vehicle_saved="loadData" v-on:vehicle_window_closed="closeModal" ></VehicleForm>
      </vmodal>

    </div>
  </FocusTrap>
</template>

<script>

import VehicleForm from './VehicleForm'
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'VehicleView',
  components: {
    VehicleForm
  },
  store,
  data () {
    return {
      mytable: {},
      vehicle: JSON.parse('{"id":0,"attnreg":"N","name":"","type":1,"fueltype":0,"openodometer":0,"lastodometer":0,"lastfuelqty":0,"owner_id":0,"category_id":0,"category":{"id":0,"name":"","type":0},"owner":{"id":0,"type":1,"name":"","alias":"","open_bal":0,"gstin":"","creditlimit":0}}')
    }
  },
  created () {

  },
  mounted () {
    const self = this;

    this.$data.mytable = $('#vehicle_table');

    this.$data.mytable.bootstrapTable();
    $('[data-toggle="popover"]').popover();

    $('#vehicle_table').contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        let id = $(row.children('*')[0]).text();

        if ($(e.target).text() === 'Modify') {
          self.modifyDocument(id);
        }else if ($(e.target).text() === 'Delete') {
          self.removeDocument(id);
        }
      }
    });

    $('#txtsearch').keyup(function () {

      let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();

      $('#vehicle_table>tbody>tr').show().filter(function () {
        var text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
        return !~text.indexOf(val)
      }).hide();

    });

    self.loadData();


  },
  methods: {
    closeModal () {
      this.$modal.hide('vehicle_window')
    },
    beforeOpen(){
      console.log('beforeOpen');
    },
    beforeClose(){
      this.vehicle = JSON.parse('{"id":0,"attnreg":"N","name":"","type":1,"fueltype":0,"openodometer":0,"lastodometer":0,"lastfuelqty":0,"owner_id":0,"category_id":0,"category":{"id":0,"name":"","type":0},"owner":{"id":0,"type":1,"name":"","alias":"","open_bal":0,"gstin":"","creditlimit":0}}');
    },
    showModal () {
      this.vehicle = JSON.parse('{"id":0,"attnreg":"N","name":"","type":1,"fueltype":0,"openodometer":0,"lastodometer":0,"lastfuelqty":0,"owner_id":0,"category_id":0,"category":{"id":0,"name":"","type":0},"owner":{"id":0,"type":1,"name":"","alias":"","open_bal":0,"gstin":"","creditlimit":0}}');
      this.$modal.show('vehicle_window')
    },
    modifyDocument (id) {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.vehicle = JSON.parse('{"id":0,"attnreg":"N","name":"","type":1,"fueltype":0,"openodometer":0,"lastodometer":0,"lastfuelqty":0,"owner_id":0,"category_id":0,"category":{"id":0,"name":"","type":0},"owner":{"id":0,"type":1,"name":"","alias":"","open_bal":0,"gstin":"","creditlimit":0}}');
      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/vehicle/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          self.$data.vehicle = resp.data;
          if(self.$data.vehicle.owner == null ){
            self.$data.vehicle.owner = {"id":0,"name":""}
          }
          self.$modal.show('vehicle_window');
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: `failed to load data!`, type:  "error"} );
      });
    },
    removeDocument (id) {
      const self = this;

      self.$data.vehicle.id = id;

      const requestOptions = {
        method: 'DELETE',
        mode:'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.vehicle)
      };

      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        timer: 3000,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete this!'
      }).then((result) => {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/vehicle`,requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('vehicle_window')
            self.loadData();
            swal(
              'Deleted!',
              'Your request has been processed',
              'success'
            );
            // swal ( { title: "Success!" ,  text: data.message, type:  "success"} );
          }).catch(function (err) {
            swal({ title: "Oops", text: err.toString(), type: "error" });
          });
        }
      });

    },
    loadData() {
      const self = this;

      this.$modal.hide('vehicle_window');

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      this.$modal.hide('vehicle_window');

      self.$data.mytable.bootstrapTable('load',[]);

      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/vehicles`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          self.$data.mytable.bootstrapTable('load', resp.data);
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $(self.$data.mytable).unblock();
      });

    },
  }

}
</script>

<style scoped>

</style>
