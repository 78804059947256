<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <div class="card-header header-elements-inline">
        <legend class="font-weight-semibold text-uppercase font-size-sm">
          <i class="icon-file-text2"></i>
          Enter Vehicle information
        </legend>

        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload"></a>
            <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
          </div>
        </div>
      </div>

      <div class="card-body">

        <div class="form-group row">
          <label class="col-md-3 col-form-label">Cateogry:</label>
          <div class="col-md-9">
            <select id="txtcategory" class="form-control" v-if="vehicle" v-model="vehicle.category_id" >
              <option v-for="group in categories" v-bind:value="group.id">
                {{ group.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-3 col-form-label">Vehicle No:</label>
          <div class="col-md-9">
            <input type="text" class="form-control text-uppercase" placeholder="Vehicle No" v-if="vehicle" v-model="vehicle.name">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-3 col-form-label">ODO Mtr:</label>
          <div class="col-md-9">
            <input type="number" class="form-control" placeholder="Open ODO Meter" v-if="vehicle" v-model="vehicle.openodometer" maxlength="14" >
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-3 col-form-label">Owner:</label>
          <div class="col-md-9">
            <select class="form-control" v-if="vehicle.owner" v-model="vehicle.owner_id" >
              <option v-for="owner in owners" v-bind:value="owner.id">
                {{ owner.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="saveDocument">Save <i class="icon-paperplane ml-2"></i></button>
        </div>

      </div>
    </div>
    </FocusTrap>
</template>

<script>
import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'VehicleForm',
  store,
  data () {
    return {
      categories:[],
      owners:[],
      vehicle: JSON.parse('{"id":0,"attnreg":"N","name":"","type":1,"fueltype":0,"openodometer":0,"lastodometer":0,"lastfuelqty":0,"owner_id":0,"category_id":0,"category":{"id":0,"name":"","type":0},"owner":{"id":0,"type":1,"name":"","alias":"","open_bal":0,"gstin":"","creditlimit":0}}')
    }
  },
  component: {

  },
  props: {
    myvehicle: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"attnreg":"N","name":"","type":1,"fueltype":0,"openodometer":0,"lastodometer":0,"lastfuelqty":0,"owner_id":0,"category_id":0,"category":{"id":0,"name":"","type":0},"owner":{"id":0,"type":1,"name":"","alias":"","open_bal":0,"gstin":"","creditlimit":0}}')
    }
  },
  beforeMount () {
    this.vehicle = this.myvehicle; // save props data to itself's data and deal with it
  },
  created () {
    this.$data.vehicle = JSON.parse('{"id":0,"attnreg":"N","name":"","type":1,"fueltype":0,"openodometer":0,"lastodometer":0,"lastfuelqty":0,"owner_id":0,"category_id":0,"category":{"id":0,"name":"","type":0},"owner":{"id":0,"type":1,"name":"","alias":"","open_bal":0,"gstin":"","creditlimit":0}}')
  },
  mounted () {
    $('.form-control-select2').select2();
    this.loadCategories();
    this.loadOwners();
    $('#txtcategory').focus();
  },
  methods: {
    closeWindow () {
      this.$emit('vehicle_window_closed');
    },
    loadCategories () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }


      self.categories = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/vehiclecategories`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            self.$data.categories = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      })
    },
    loadOwners () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }


      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/customers/active`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            self.$data.owners = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      });
    },
    saveDocument () {
      const self = this;

      try {
        self.$data.vehicle.name = self.$data.vehicle.name.toString().toUpperCase();

        if (self.$data.vehicle.name.toString().length < 7) {
          alert('Invalid Vehicle No');
          return
        } else if (self.$data.vehicle.category_id < 1) {
          alert('Invalid Vehicle Category');
          return
        } else if (self.$data.vehicle.owner_id < 1) {
          alert('Invalid Vehicle Owner');
          return
        }

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.vehicle.openodometer = parseInt(self.$data.vehicle.openodometer);

        if (self.$data.vehicle.owner_id == 0) {
          self.$data.vehicle.owner_id = 1;
        } else if (self.$data.vehicle.category_id == 0) {
          self.$data.vehicle.category_id = 1;
        }

        const requestOptions = {
          method: (self.$data.vehicle.id == 0 ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.vehicle)
        }

        fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/vehicle`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({
              title: 'Success!', text: resp.msg, type: 'success', onClose: () => {
                $('#txtcategory').focus()
              }, timer: 1500
            });
            self.vehicle = JSON.parse('{"id":0,"attnreg":"N","name":"","type":1,"fueltype":0,"openodometer":0,"lastodometer":0,"lastfuelqty":0,"owner_id":0,"category_id":0,"category":{"id":0,"name":"","type":0},"owner":{"id":0,"type":1,"name":"","alias":"","open_bal":0,"gstin":"","creditlimit":0}}');
            self.$emit('vehicle_saved', resp.data);
          } else {
            swal({
              title: 'Oops!', text: resp.msg, type: 'error', onClose: () => {
                $('#txtcategory').focus()
              }, timer: 3000
            });
          }
        }).catch(function (err) {
          swal({
            title: 'Oh noes!', text: err.toString(), type: 'error', onClose: () => {
              $('#txtcategory').focus()
            }, timer: 3000
          });
        }).finally(()=>{
          $('#mycard').unblock();
        });

      }catch (e) {
        alert(e);
      }
    }
  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}
</style>
